<template>
  <div>
    <AuBreadcrumb />
    <div class="card dividends-history">
      <div class="title">
        {{ $t("dividendsHistory.label") }}
      </div>
      <AuTable
        :columns="columns"
        :items="items"
        :loading="dataLoading"
      />
      <AuPagination
        v-if="dividendsHistoryTotal > itemsPerPage"
        :total-items="dividendsHistoryTotal"
        @pageChanged="changePage"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DividendsHistory",
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      columns: [
        { name: this.$t("dividendsHistory.firstColumn"), align: "start" },
        { name: this.$t("dividendsHistory.secondColumn"), align: "start" },
        { name: this.$t("dividendsHistory.thirdColumn"), align: "start" },
        { name: this.$t("dividendsHistory.fourthColumn"), align: "start" },
        { name: this.$t("dividendsHistory.fifthColumn"), align: "start" }
      ],
      page: 1,
      itemsPerPage: 30
    };
  },

  computed: {
    ...mapGetters({
      dividendsHistory: "dividends/dividendsHistory",
      dividendsHistoryTotal: "dividends/dividendsHistoryTotal",
      dataLoading: "dividends/dataLoading"
    }),

    items() {
      return _.map(this.dividendsHistory, item => {
        return {
          amountToken: item.amountToken,
          amountTokenReserved: item.amountTokenReserved,
          status: item.status,
          createdAt: moment(item.createdAt).format("DD.MM.YYYY"),
          ethAddress: item.ethAddress
        };
      });
    }
  },

  async mounted() {
    await this.getDividendsHistory({
      page: this.page,
      itemsPerPage: this.itemsPerPage
    });
  },

  methods: {
    ...mapActions({
      getDividendsHistory: "dividends/getDividendsHistory"
    }),

    changePage(page) {
      this.page = page;
      this.getDividendsHistory({
        page: this.page,
        itemsPerPage: this.itemsPerPage
      });
    }
  }
};
</script>

<style scoped lang="scss">
.dividends-history{
  padding: 20px;

  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
</style>