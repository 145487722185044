/* eslint-disable indent */
import _ from "lodash";
import { createStore } from "vuex";

import AxiosController from "@/api/axiosController";
import BlogPageController from "@/api/blogPageController";

import capitals from "./capitals/module";
import dao from "./dao/module";
import defaultToken from "./defaultToken/module";
import dividends from "./dividends/module";
import files from "./files/module";
import goods from "./goods/module";
import marketplace from "./marketplace/module";
import message from "./message/module";
import news from "./news/module";
import payment from "./payment/module";
import profile from "./profile/module";
import socket from "./socket/module";
import withdrawal from "./withdrawal/module";

const axiosController = new AxiosController();
const blogPageController = new BlogPageController();

import i18n from "../localization/localization";

let lang = i18n.global.locale ? i18n.global.locale : "en";

export default createStore({
  modules: {
    "marketplace": marketplace,
    "goods": goods,
    "news": news,
    "dividends": dividends,
    "dao": dao,
    "payment": payment,
    "defaultToken": defaultToken,
    "message": message,
    "files": files,
    "capitals": capitals,
    "profile": profile,
    "withdrawal": withdrawal,
    "socket": socket
  },

  state: {
    isTokenVerify: "",
    language: lang,
    accountAddress: null,
    mmInstalled: false,
    isLightTheme: true,
    isChatVisible: false,
    auOwners: [],
    blogPages: [],
    currencyRate: [],
    transactions: [],
    sectionMarket: [],
    sectionCapital: [],
    country: [],
    tokensFiat: [],
    tokensUtility: [],
    tokensDao: [],
    tokensGold: [],
    tokens: [],
    followings: [],
    balances: [],
    balancesGold: [],
    requestsToIssue: [],
    ethAddresses: [],
    companies: [],
    userToNftTransactions: [],
    userFromNftTransactions: [],
    counterparties: [],
    nftOwners: [],
    history: [],
    trades: [],
    pairs: [],
    reports: [
      {
        companyName: "Севергазбанк",
        icon: "sb_logo",
        date: "04.10.2022",
        period: "08.09 – 04.10"
      },
      {
        companyName: "Сфера",
        icon: "s_logo",
        date: "01.09.2022",
        period: "08.05 – 01.09"
      }
    ],
    dividents: [
      {
        companyName: "Севергазбанк",
        icon: "sb_logo",
        tockensCount: 26,
        tockenCost: 12353,
        expectedDividends: 9492,
        dividentsDate: "19.10.2022"
      },
      {
        companyName: "Сфера",
        icon: "s_logo",
        tockensCount: 17,
        tockenCost: 3124,
        expectedDividends: 1739,
        dividentsDate: "19.10.2022"
      },
      {
        companyName: "EABR",
        icon: "eabr_logo",
        tockensCount: 5,
        tockenCost: 17932,
        expectedDividends: 921,
        dividentsDate: "22.10.2022"
      },
      {
        companyName: "Аверс",
        icon: "a_logo",
        tockensCount: 87,
        tockenCost: 9192,
        expectedDividends: 19284,
        dividentsDate: "22.10.2022"
      }
    ],
    tokenHistory: [],
    exchangeBalances: [],
    spot: null,
    pay: null,
    outerPath: null,
    projectCount: null,
    companyCount: null
  },

  getters: {
    language: state => {
      return state.language;
    },

    accountAddress: state => {
      return state.accountAddress;
    },

    mmInstalled: state => {
      return state.mmInstalled;
    },

    isTokenVerify: state => {
      return state.isTokenVerify;
    },

    isLightTheme: state => {
      return state.isLightTheme;
    },

    isChatVisible: state => {
      return state.isChatVisible;
    },

    companies: state => {
      return state.companies;
    },

    userToNftTransactions: state => {
      return state.userToNftTransactions;
    },

    userFromNftTransactions: state => {
      return state.userFromNftTransactions;
    },

    dividents: state => {
      return state.dividents;
    },

    reports: state => {
      return state.reports;
    },

    auOwners: state => {
      return state.auOwners;
    },

    nftOwners: state => {
      return state.nftOwners;
    },

    blogPages: state => {
      return state.blogPages;
    },

    currencyRate: state => {
      return state.currencyRate;
    },

    transactions: state => {
      return state.transactions;
    },

    sectionMarket: state => {
      return state.sectionMarket;
    },

    sectionCapital: state => {
      return state.sectionCapital;
    },

    country: state => {
      return state.country;
    },

    tokensFiat: state => {
      return state.tokensFiat;
    },

    tokensUtility: state => {
      return state.tokensUtility;
    },

    tokensDao: state => {
      return state.tokensDao;
    },

    tokensGold: state => {
      return state.tokensGold;
    },

    tokens: state => {
      return state.tokens;
    },

    requestsToIssue: state => {
      return state.requestsToIssue;
    },

    followings: state => {
      return state.followings;
    },

    counterparties: state => {
      return state.counterparties;
    },

    balances: state => {
      return state.balances;
    },

    balancesGold: state => {
      return state.balancesGold;
    },

    ethAddresses: state => {
      return state.ethAddresses;
    },

    history: state => {
      return state.history;
    },

    trades: state => {
      return state.trades;
    },

    tokenHistory: state => {
      return state.tokenHistory;
    },

    pairs: state => {
      return state.pairs;
    },

    exchangeBalances: state => {
      return state.exchangeBalances;
    },

    spot: state => {
      return state.spot;
    },

    pay: state => {
      return state.pay;
    },

    outerPath: state => {
      return state.outerPath;
    },

    projectCount: state => {
      return state.projectCount;
    },

    companyCount: state => {
      return state.companyCount;
    }
  },

  mutations: {
    accountAddress(state, value) {
      state.accountAddress = value;
    },

    setEthAddresses(state, value) {
      state.ethAddresses = value;
    },

    setRequestsToIssue(state, value) {
      state.requestsToIssue = value;
    },

    mmInstalled(state, value) {
      state.mmInstalled = value;
    },

    switchTheme(state) {
      state.isLightTheme = !state.isLightTheme;
    },

    setChatVisible(state, isChatVisible) {
      state.isChatVisible = isChatVisible;
    },

    setIsTokenVerify(state, value) {
      state.isTokenVerify = value;
    },

    setIsLightTheme(state, value) {
      state.isLightTheme = value;
    },

    setAuOwners(state, owners) {
      state.auOwners = owners;
    },

    setBlogPages(state, blogPages) {
      state.blogPages = blogPages;
    },

    setCurrencyRate(state, currencyRate) {
      state.currencyRate = currencyRate;
    },

    setTransactions(state, transactions) {
      state.transactions = transactions;
    },

    setSectionMarket(state, section) {
      state.sectionMarket = section;
    },

    setSectionCapital(state, section) {
      state.sectionCapital = section;
    },

    setCountry(state, country) {
      state.country = country;
    },

    setCounterparties(state, counterparties) {
      state.counterparties = counterparties;
    },

    setTokensFiat(state, tokensFiat) {
      state.tokensFiat = tokensFiat;
    },

    setTokensUtility(state, tokensUtility) {
      state.tokensUtility = tokensUtility;
    },

    setTokensDao(state, tokensDao) {
      state.tokensDao = tokensDao;
    },

    setTokensGold(state, tokensGold) {
      state.tokensGold = tokensGold;
    },

    setAuTokens(state, tokens) {
      state.tokens = tokens;
    },

    setCompanies(state, companies) {
      state.companies = companies;
    },

    setUserFromNftTransactions(state, nftTransactions) {
      state.userFromNftTransactions = nftTransactions;
    },

    setUserToNftTransactions(state, nftTransactions) {
      state.userToNftTransactions = nftTransactions;
    },

    setFollowings(state, followings) {
      state.followings = followings;
    },

    setAuBalances(state, balances) {
      state.balances = balances;
    },

    setAuBalancesGold(state, balances) {
      state.balancesGold = balances;
    },

    setNftOwners(state, owners) {
      state.nftOwners = owners;
    },

    setTokenHistory(state, tokenHistory) {
      state.tokenHistory.push(tokenHistory);
    },

    setLanguage(state, language) {
      state.language = language;
    },

    setExchangeBalances(state, exchangeBalances) {
      state.exchangeBalances = exchangeBalances;
    },

    setHistory(state, history) {
      state.history = history;
    },

    setTrades(state, trades) {
      state.trades = trades;
    },

    setPairs(state, pairs) {
      state.pairs = pairs;
    },

    setSpot(state, spot) {
      state.spot = spot;
    },

    setPay(state, pay) {
      state.pay = pay;
    },

    setOuterPath(state, outerPath) {
      state.outerPath = outerPath;
    },

    setProjectCount(state, projectCount) {
      state.projectCount = projectCount;
    },

    setCompanyCount(state, companyCount) {
      state.companyCount = companyCount;
    }
  },

  actions: {
    accountAddress({ commit }, value) {
      commit("accountAddress", value);
    },

    mmInstalled({ commit }, value) {
      commit("mmInstalled", value);
    },

    switchTheme({ commit }) {
      commit("switchTheme");
    },

    setIsTokenVerify({ commit }, value) {
      commit("setIsTokenVerify", value);
    },

    setIsLightTheme({ commit }, value) {
      commit("setIsLightTheme", value);
    },

    setChatVisible({ commit }, value) {
      commit("setChatVisible", value);
    },

    async postCapitalMp(_context, body) {
      await axiosController.postCapitalMp(body);
    },

    async getCompanies({ commit, getters }, companyRequest = true) {
      let companiesData;

      if (companyRequest) {
        companiesData = await axiosController.getCompanies();
      }
      else {
        companiesData = getters.companies;
      }

      let requisitesData = await axiosController.getBankRequisites();
      if (!_.isEmpty(requisitesData)) {
        requisitesData.forEach(requisites => {
          let splittedCompanyString = requisites.company.split("/");
          let companyId = splittedCompanyString[splittedCompanyString.length - 1];
          let companyIndex = _.findIndex(companiesData, { id: Number(companyId) });
          if (companyIndex !== -1) {
            companiesData[companyIndex].requisites = requisites;
          }
        });
      }

      commit("setCompanies", companiesData);
    },

    async getHydraCompanies({ commit }, params) {
      const resp = await axiosController.getHydraCompanies(params);
      commit("setCompanyCount", resp.totalItems);
      return resp;
    },

    async getUsersNftTransactions({ commit }, userId) {
      let dataFromUser = await axiosController.getUserFromNftTransactions(userId);
      let dataToUser = await axiosController.getUserToNftTransactions(userId);

      commit("setUserFromNftTransactions", dataFromUser);
      commit("setUserToNftTransactions", dataToUser);
    },

    async getAuTokens({ commit }) {
      let data = await axiosController.getAuTokens();
      commit("setAuTokens", data);
      let fiat = [], dao = [], gold = [], utility = [];
      data.forEach(item => {
        if (item.mnemonicEng === process.env.VUE_APP_TOKEN_DEFAULT) {
          utility.unshift(item);
        }
        switch (item.type) {
          // eslint-disable-next-line indent
          case "fiat": fiat.push(item); break;
          case "dao": dao.push(item); break;
          case "gold": gold.push(item); break;
          case "utility": utility.push(item); break;
        }
      });
      commit("setTokensUtility", utility);
      commit("setTokensFiat", fiat);
      commit("setTokensDao", dao);
      commit("setTokensGold", gold);
    },

    async getAuOwners({ commit }) {
      let data = await axiosController.getAuOwners();
      commit("setAuOwners", data);
    },

    async getBlogPages({ commit }) {
      let data = await blogPageController.getBlogPages({ pageId: 1 });
      commit("setBlogPages", data);
    },

    async getCurrencyRate({ commit }) {
      let data = await axiosController.getCurrencyRate();
      commit("setCurrencyRate", data);
    },

    async getTransactions({ commit }) {
      let data = await axiosController.getTransactions();
      commit("setTransactions", data);
    },

    async getSectionMarket({ commit, getters }) {
      let data = await axiosController.getSectionMarket(getters.language);
      commit("setSectionMarket", data);
    },

    async getSectionCapital({ commit, getters }) {
      let data = await axiosController.getSectionCapital(getters.language);
      commit("setSectionCapital", data);
    },

    async getCountry({ commit, getters }) {
      let data = await axiosController.getCountry(getters.language);
      commit("setCountry", data);
    },

    async getCounterparties({ commit }) {
      let data = await axiosController.getCounterparties();
      commit("setCounterparties", data);
    },

    async getFollowings({ commit }) {
      let data = await axiosController.getFollowings();
      commit("setFollowings", data);
    },

    async setFollowing({ dispatch }, id) {
      await axiosController.setFollowing(id);
      dispatch("getFollowings");
    },

    async postCounterparty({ dispatch }, body) {
      await axiosController.postCounterparty(body);
      await dispatch("getCounterparties");
    },

    async postBankRequisites({ dispatch }, body) {
      await axiosController.postBankRequisites(body);
      await dispatch("getCompanies", false);
    },

    async deleteBankRequisites({ dispatch }, id) {
      await axiosController.deleteBankRequisites(id);
      await dispatch("getCompanies");
    },

    async updateBankRequisites({ dispatch }, { id, newValue }) {
      await axiosController.updateBankRequisites(id, newValue);
      await dispatch("getCompanies", false);
    },

    async unsetFollowing({ dispatch }, id) {
      await axiosController.unsetFollowing(id);
      dispatch("getFollowings");
    },

    async setRequestToIssue({ dispatch }, requestToIssue) {
      const resp = await axiosController.setRequestToIssue(requestToIssue);
      dispatch("getRequestsToIssue");
      return resp;
    },

    async putRequestToIssue({ dispatch }, { requestToIssue, id }) {
      await axiosController.putRequestToIssue({ requestToIssue, id });
      dispatch("getRequestsToIssue");
    },

    async delRequestToIssue(_context, id) {
      return await axiosController.delRequestToIssue(id);
    },

    async editRequestToIssue(_context, [id, params]) {
      return await axiosController.editRequestToIssue(id, params);
    },

    async getBalances({ commit }, tokenQuote) {
      let data = await axiosController.getBalances(tokenQuote);
      commit("setAuBalances", data);
    },

    async getBalancesGold({ commit }, tokenQuote) {
      let data = await axiosController.getBalances(tokenQuote);
      commit("setAuBalancesGold", data);
    },

    async sendTokenEvents(_contex, params) {
      return await axiosController.sendTokenEvents(params);
    },

    async sendTokenEventsById(_contex, { id, params }) {
      return await axiosController.sendTokenEventsById(id, params);
    },


    async getTokenEvents(_contex, { pageId, capitalId, type }) {
      return await axiosController.getTokenEvents(pageId, capitalId, type);
    },

    async getTokenEventById(_contex, id) {
      return await axiosController.getTokenEventById(id);
    },

    async getNftOwners({ commit }) {
      let data = await axiosController.getNftOwners();
      commit("setNftOwners", data);
    },

    async getTokenHistory({ commit }, { defaultTokenId, tokenId }) {
      let data = await axiosController.getTokenHistory({ defaultTokenId, tokenId });
      let tokenInfo = {
        tokenId: tokenId,
        tokenHistory: data
      };
      commit("setTokenHistory", tokenInfo);
    },

    async getNftOwnersPage({ commit }, { page, userId }) {
      let data = await axiosController.getNftOwnersPage(page, userId);
      commit("setNftOwners", data.data);
      return data;
    },

    async getTransactionsPage(_contex, { pageId, sort, auTokenId, adr, dateFrom, dateTo, itemsPerPage, addressFrom, addressTo, methodName }) {
      return await axiosController.getTransactionsPage({ pageId, sort, auTokenId, adr, dateFrom, dateTo, itemsPerPage, addressFrom, addressTo, methodName });
    },

    async getTransactionsHistory(_contex, params) {
      return await axiosController.getTransactionsHistory(params);
    },

    async getTransactionsPageOwnerFrom(_contex, { pageId, ownerId }) {
      return await axiosController.getTransactionsPageOwnerFrom(pageId, ownerId);
    },

    async getTransactionsPageOwnerTo(_contex, { pageId, ownerId }) {
      return await axiosController.getTransactionsPageOwnerTo(pageId, ownerId);
    },

    async getTransactionsByAddress(_contex, { addressFrom, addressTo }) {
      return await axiosController.getTransactionsByAddress(addressFrom, addressTo);
    },

    async getProfile({ commit }, id) {
      let data = await axiosController.getProfile(id);
      commit("setProfile", data);
    },

    async changeProfile(_context, data) {
      const params = {
        iName: data?.iName,
        fName: data?.fName,
        oName: data?.oName
      };
      await axiosController.changeProfile(data.id, params);
    },

    register(_context, data) {
      return axiosController.register(data);
    },

    async defaultRequest(_context, request) {
      return await axiosController.defaultRequest(request);
    },

    async requestForms(_context, body) {
      await axiosController.requestForms(body);
    },

    async getEthAddresses({ commit }) {
      let ethAddresses = await axiosController.getEthAddresses();
      commit("setEthAddresses", ethAddresses);
    },

    async postEthAddresses(_context, body) {
      return await axiosController.postEthAddresses(body);
    },

    async patchEthAddresses(_context, { id, body }) {
      return await axiosController.patchEthAddresses(id, body);
    },

    async getRequestsToIssue({ commit }) {
      let data = await axiosController.getRequestsToIssue();
      commit("setRequestsToIssue", data);
    },

    async getHydraRequestToIssue({ commit }, params) {
      const resp = await axiosController.getHydraRequestToIssue(params);
      commit("setRequestsToIssue", resp.data);
      commit("setProjectCount", resp.totalItems);
      return resp;
    },

    async emailVerify(_context, body) {
      return await axiosController.emailVerify(body);
    },

    async deleteEthAddresses(_context, id) {
      return await axiosController.deleteEthAddresses(id);
    },

    async deleteCounterparty({ dispatch }, id) {
      await axiosController.deleteCounterparty(id);
      dispatch("getCounterparties");
    },

    async updateCounterparty({ dispatch }, { id, newValue }) {
      await axiosController.updateCounterparty(id, newValue);
      dispatch("getCounterparties");
    },

    async getValidateContent(_context, currentEth) {
      return await axiosController.getValidateContent(currentEth);
    },

    async signAndVerify(_context, { accountAddress, sign }) {
      return await axiosController.signAndVerify(accountAddress, sign);
    },

    async addCompany(_context, params) {
      return await axiosController.addCompany(params);
    },

    async addCompanyFromWelcome({ dispatch }, params) {
      await axiosController.addCompanyFromWelcome(params);
      dispatch("getCompanies");
    },

    async editCompany(_context, [id, params]) {
      return await axiosController.editCompany(id, params);
    },

    async deleteCompany(_context, id) {
      return await axiosController.deleteCompany(id);
    },

    setLanguage({ commit }, language) {
      commit("setLanguage", language);
      document.cookie = `lang=${language}; path=/`;
      document.querySelector("html").setAttribute("lang", language);
      localStorage.setItem("user-locale", language);
      i18n.global.locale = language;
    },

    async getExchangeBalances({ commit }, userId) {
      const data = await axiosController.getExchangeBalances(userId);

      commit("setExchangeBalances", data);
    },

    async getHistory({ commit }, { dateFrom, dateTo, userId, tokenId }) {
      commit("setHistory", []);
      const data = await axiosController.getHistory(dateFrom, dateTo, userId, tokenId);
      commit("setHistory", data);
    },

    async getTrades({ commit }, { page, userId, pairId }) {
      const { data, totalItems } = await axiosController.getTrades(page, userId, pairId);
      commit("setTrades", data);
      return totalItems;
    },

    async getCourse(_context, { tokenId, tokenQuoteId }) {
      return await axiosController.getCourse(tokenId, tokenQuoteId);
    },

    async getPairs({ commit }) {
      const data = await axiosController.getPairs();
      commit("setPairs", data);
    },

    async patchCurrentUser(_context, { userId, body }) {
      await axiosController.patchCurrentUser({ userId, body });
    },

    async unsubscribe(_context, secret) {
      return await axiosController.unsubscribe(secret);
    },

    async postPassword(_context, { params }) {
      return await axiosController.changePassword(params);
    },

    async get2Fa(_context, userId) {
      return await axiosController.getQr2FA(userId);
    },

    async postEnable2Fa(_context, { params }) {
      return await axiosController.on2Fa(params);
    },

    async postDisable2Fa(_context, { params }) {
      return await axiosController.off2Fa(params);
    },

    async getOwnerPage(_context, params) {
      return await axiosController.getOwnerPage(params);
    },

    async getAuOwnerByTokenId(_context, { tokenId, ownerAddress }) {
      return await axiosController.getAuOwnerByTokenId(tokenId, ownerAddress);
    },

    async getSpot({ commit }, { userId, tokenId }) {
      const resp = await axiosController.getExchangeBalances(userId, tokenId);
      commit("setSpot", resp);
    },

    async payCreate(_context, params) {
      return await axiosController.payCreate(params);
    },

    async getPay({ commit }, id) {
      commit("setPay", await axiosController.getPay(id));
    },

    async payNotify(_context, payId) {
      return axiosController.payNotify(payId);
    },

    async restorePassword(_context, email) {
      return await axiosController.restorePassword(email);
    },

    async restoreRequestCheck(_context, hash) {
      return await axiosController.restoreRequestCheck(hash);
    },

    setOuterPath({ commit }, path) {
      commit("setOuterPath", path);
    },

    getCaptcha(_context) {
      return axiosController.getCaptcha();
    }
  }
});